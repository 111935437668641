<template>
    <div class="page-coach-will-survey iphonex-pb">
        <HeadeBar title="我要做教练" left-arrow @click-left="newAppBack">
            <div slot="right">
                <img class="share-logo" @click="showShare = true"
                    src="https://img.chaolu.com.cn/ACT/coach-will-survey/share.png" />
            </div>
        </HeadeBar>
        <div class="c-banner">
            <img src="https://img.chaolu.com.cn/ACT/coach-will-survey/banner.png" />
        </div>
        <div class="c-body">
            <form @submit.prevent="submit" action="">
                <div class="c-title">1. 您想成为教练的原因是<span class="must" v-show="!check('reason')">（请选择一项）</span> </div>
                <div class="c-item" :class="{ 'c-active': reasonIndex === index }" v-for="(item, index) in reasonList"
                    @click="reasonIndex = index, formData.reason = ''">{{ item }}
                </div>
                <div class="c-item" :class="{ 'c-active': reasonIndex === -1 }">
                    <input class="ip" v-model.trim="formData.reason" :placeholder="reasonIndex === -1?'请输入':'其他'" @focus="reasonIndex = -1"
                        maxlength="50" autocomplete="off" type="text" />
                </div>
                <div class="c-title">2. 请留下您的尊姓大名<span class="must" v-show="!check('name')">（请输入）</span> </div>
                <div class="c-item">
                    <input class="ip" v-model.trim="formData.name" type="text" placeholder="请输入" maxlength="8" />
                </div>
                <div class="c-title">3. 您的性别是<span class="must" v-show="!check('sex')">（请至少选择一项）</span> </div>
                <div class="c-sex c-fw">
                    <div class="c-item" :class="{ 'c-active': sexIndex === index }" v-for="(item, index) in sexList"
                        @click="sexIndex = index">我是一个{{ item }}生</div>
                </div>
                <div class="c-title">4. 您的联系方式<span class="must" v-show="!check('mobile')">（请输入正确的手机号）</span> </div>
                <div class="c-item">
                    <input class="ip" inputmode="numeric" v-model.trim="formData.mobile" type="text" placeholder="请输入"
                        maxlength="13" />
                </div>
                <div class="c-title">
                    5. 意愿工作城市
                    <div class="c-multiple"><span>可多选</span></div>
                    <span class="must" v-show="!check('willCity')">（请至少选择一项）</span>
                </div>
                <div class="c-city c-fw">
                    <div class="c-item" :class="{ 'c-active': citySelectJson[key] }" v-for="(value, key) in cityJson"
                        @click="citySelectJson[key] = !citySelectJson[key], formData.otherCity = '',otherCityActive=false">{{ value }}</div>
                    <div class="c-item" :class="{ 'c-active': formData.otherCity }">
                        <input class="ip" v-model.trim="formData.otherCity" type="text" :placeholder="otherCityActive?'请输入':'其他'"
                            @focus="clearCity(),otherCityActive=true" @blur="" maxlength="20" />
                    </div>
                </div>
                <div class="c-title">
                    6. 您想成为的教练方向
                    <div class="c-multiple"><span>可多选</span></div>
                    <span class="must" v-show="!check('derection')">（请至少选择一项）</span>
                </div>
                <div class="c-item" :class="{ 'c-active': waySelectJson[item] }" v-for="item in wayList"
                    @click="waySelectJson[item] = !waySelectJson[item]">{{ item }}</div>
                <div class="c-submit" :class="{ 'iphonex-pb': showSafe }">
                    <input class="c-button" type="submit" value="提交">
                </div>
            </form>
        </div>
        <van-popup v-model="showOK" position="center" class="pop-get">
            <div class="pop-get-title">
                提交成功
            </div>
            <div class="pop-get-tips">
                信息提交成功，感谢您的信任。我们将在3个工作日内联系您！
            </div>
            <div class="pop-get-sub" @click="showOK = false">我知道了</div>
        </van-popup>
        <common-share :value="showShare" :shareParams="shareParams" :shareItem="['minifriend']"
            @close="showShare = false" @share-success="showShare = false">
        </common-share>
    </div>
</template>

<script>
import { newAppBack, initBack } from '@/lib/appMethod';
import HeadeBar from '@/components/app/headBar';
import CommonShare from '@/components/commonShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk'

export default {
    mixins: [userMixin],
    components: {
        HeadeBar,
        CommonShare,
    },
    data() {
        const baseCity = {
            3: '福州',
            '036652995ac5451fbb6791c3491e1c2d': '厦门',
            [this.baseURLApp == 'http://apptest.chaolu.com.cn' ? '816260796311932928' : '818447834079563776']: '广州'
        }
        function getDefaultCity() {
            const json = {}
            for (let i in baseCity) {
                json[i] = false
            }
            return json
        }
        const baseWay = ['私教', '团操课', '特色课（如拳击、飞盘、产后修复、筋膜刀等）']
        function getDefaultWay() {
            const json = {}
            for (let i = 0; i < baseWay.length; i++) {
                json[baseWay[i]] = false
            }
            return json
        }
        return {
            reasonList: ['我是运动健身相关专业毕业生，考虑从事与专业相关职业', '我是一位健身爱好者，想把兴趣转变为职业', '我本身是健身教练，希望能寻找更合适的平台'],
            sexList: ['男', '女'],
            wayList: baseWay,
            reasonIndex: undefined,
            sexIndex: undefined,
            cityJson: baseCity,
            citySelectJson: getDefaultCity(),
            waySelectJson: getDefaultWay(),
            formData: {
                name: ''
            },
            showCheck: false,
            showOK: false,
            canClick: true,
            showShare: false,
            otherCityActive:false,
            shareParams: {
                title: '成为超鹿教练，让生命更澎拜',
                miniImage: 'https://img.chaolu.com.cn/ACT/coach-will-survey/mini.png',
                path: `/pages/webView/index?webUrl=${window.location.origin}/#/coach-will-survey`,
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            showSafe: true
        }
    },
    methods: {
        newAppBack,
        clearCity() {
            for (let i in this.citySelectJson) {
                this.citySelectJson[i] = false;
            }
        },
        submit() {
            if (!this.canClick) {
                this.$toast('您已提交过了');
                return;
            }
            this.showCheck = true;
            if (this.check()) {
                const f = this.formData;
                const direction = []
                for (let i in this.waySelectJson) {
                    if (this.waySelectJson[i]) {
                        direction.push(i)
                    }
                }
                const cityName = [];
                const cityId = [];
                for (let i in this.citySelectJson) {
                    if (this.citySelectJson[i]) {
                        cityName.push(this.cityJson[i])
                        cityId.push(i)
                    }
                }
                if (f.otherCity) {
                    cityName.push(f.otherCity);
                    cityId.push(-1)
                }
                const ps = {
                    direction: direction.join(','),
                    mobile: f.mobile,
                    name: f.name,
                    reason: f.reason || this.reasonList[this.reasonIndex],
                    sex: this.sexList[this.sexIndex],
                    willCity: cityName.join(','),
                    willCityId: cityId.join(',')
                }
                console.log(ps);
                this.$toast.loading({
                    duration: 0,
                    forbidClick: true,
                    loadingType: 'spinner',
                })
                this.$axios
                    .post(this.baseURLApp + "/teacherRecruitWillingRecord/submit", {
                        ...ps,
                        userId: this.userId,
                        token: this.token
                    })
                    .then((res) => {
                        this.showOK = true;
                        this.canClick = false;
                    }).catch(() => {

                    }).then(() => {
                        this.$toast.clear()
                    })
            } else {
                this.$toast('请完善资料');
            }
        },
        check(prop) {
            if (!this.showCheck) {
                return true
            }
            if (prop == 'reason' || !prop) {
                if ((this.reasonIndex == -1 && !this.formData.reason) || this.reasonIndex === undefined) {
                    return false
                }
            }
            if (prop == 'name' || !prop) {
                if (!this.formData.name) {
                    return false
                }
            }
            if (prop == 'sex' || !prop) {
                if (this.sexIndex === undefined) {
                    return false
                }
            }
            if (prop == 'mobile' || !prop) {
                const mobile = this.formData.mobile;
                if (!(/^1[0-9]{10}$/).test(mobile)) {
                    return false
                }
            }
            if (prop == 'willCity' || !prop) {
                let err = true;
                for (let i in this.citySelectJson) {
                    if (this.citySelectJson[i]) {
                        err = false;
                        break;
                    }
                }
                if (err && !this.formData.otherCity) {
                    return false
                }
            }
            if (prop == 'derection' || !prop) {
                let err = true;
                for (let i in this.waySelectJson) {
                    if (this.waySelectJson[i]) {
                        err = false;
                        break;
                    }
                }
                if (err) {
                    return false
                }
            }
            return true;
        }
    },
    mounted() {
        //安卓手机弹出键盘时会修改window的高度，iOS则不会，只是向上滚动了一个键盘的高度
        // let height=window.innerHeight;
        // console.log('default:'+height);
        // window.visualViewport.addEventListener("resize", ()=>{
        //     console.log('b:'+window.visualViewport.height);
        // },false);
        if (this.appTypeStr == 'ios') {
            const domList = document.querySelectorAll('.ip');
            for (let i = 0; i < domList.length; i++) {
                domList[i].addEventListener('focus', () => {
                    this.showSafe = false;
                })
                domList[i].addEventListener('blur', () => {
                    this.showSafe = true;
                })
            }
        }
    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId']);
        const uu = encodeURIComponent(`${window.location.origin}/#/coach-will-survey`)
        this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
        wx.miniProgram.postMessage({
            data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
            },
        })
    }
}
</script>

<style lang="less" scoped>
.page-coach-will-survey {
    background: #fff;
    // position: fixed;
    ////height: 100vh;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .share-logo {
        width: 40px;
        height: 40px;
    }

    .c-banner {
        margin: 33px 32px 0;
        border-radius: 10px;
        overflow: hidden;
        min-height: 270px;
        background: #f5f5f5;
    }

    .c-body {
        margin: 0 32px;
        padding-bottom: 200px;

        .c-title {
            color: #242831;
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 39px;
            margin-top: 70px;
            line-height: 1;
            display: flex;
            align-items: center;

            .must {
                color: #ED5C42;
                font-size: 24px;
                margin-left: 17px;
            }

            .c-multiple {
                width: 100px;
                height: 40px;
                background: #F5F5F5;
                margin-left: 16px;
                border-radius: 20px;

                span {
                    font-weight: bold;
                    color: #6C727A;
                    text-align: center;
                    display: block;
                    font-size: 24px;
                    transform: scale(0.9);
                    transform-origin: 50% 50%;
                    line-height: 42px;
                }
            }
        }

        .c-item {
            height: 76px;
            line-height: 1;
            display: flex;
            align-items: center;
            border-radius: 8px;
            background: #F5F5F5;
            font-size: 24px;
            color: #242831;
            margin-bottom: 24px;
            padding: 0 30px;
            position: relative;

            .ip {
                height: 100%;
                border: none;
                background: transparent;
                width: 100%;
                position: relative;
                z-index: 1;
            }
        }

        .c-active {
            font-weight: bold;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px solid #232831;
                border-radius: 8px;
                z-index: 0;
            }
        }

        .c-sex {
            display: flex;
            flex-wrap: wrap;

            .c-item {
                width: 212px;
                margin-right: 25px;
            }
        }

        .c-city {
            display: flex;
            flex-wrap: wrap;

            .c-item {
                width: 212px;
                justify-content: center;

                &:nth-child(3n+2) {
                    margin-left: auto;
                    margin-right: auto;
                }

                .ip {
                    text-align: center;
                }
            }
        }

        .c-fw {
            .c-item {
                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-last-child(3) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .c-submit {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 999;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 32px;
        padding-right: 32px;

        .c-button {
            width: 100%;
            text-align: center;
            margin: 20px 0;
            background: #FFDE00;
            line-height: 96px;
            color: #242831;
            font-size: 32px;
            border: none;
            font-weight: bold;
            border-radius: 8px;
        }
    }

    .pop-get {
        border-radius: 12px;
        width: 590px;
        height: 402px;
        background: #fff;
        text-align: center;
        padding: 0 40px;

        .pop-get-title {
            color: #242831;
            font-weight: bold;
            font-size: 36px;
            line-height: 1;
            margin-top: 56px;
            margin-bottom: 50px;
        }

        .pop-get-tips {
            color: #242831;
            font-size: 24px;
            line-height: 40px;
        }


        .pop-get-sub {
            margin-top: 45px;
            line-height: 96px;
            color: #242831;
            background: #FFDE00;
            border-radius: 8px;
            font-weight: bold;
        }
    }
}
</style>